$dark-grey1: #202020;
$dark-grey2: #212121;
$light-green1: #1BB1A9;
$light-green2: #22B1A9;
$light-blue1: #199FBB;
$light-blue2: #66CCFF;
$dark-blue1: #0070AB;
$light-grey1: #CCCCCC;
$light-grey2: #ECECEC;
$light-grey3: #DDDDDD;
$light-grey4: #767676;
$light-grey5: #999999;
$light-grey6: #F7F7F7;
$light-grey7: #F0F0F0;
$light-grey8: #707070;
$light-grey9: #DCDCDC;