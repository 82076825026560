@import '/src/App/Global/Style/_variables.scss';

@font-face {
  font-family: UOLText;
  src: url(/Assets/Fonts/UolText/uol-text-regular.woff);
}

@font-face {
  font-family: UOLText;
  src: url(/Assets/Fonts/UolText/uol-text-bold.woff);
  font-weight: bold;
}

@font-face {
  font-family: UOLText;
  src: url(/Assets/Fonts/UolText/uol-text-light.woff);
  font-weight: light;
}

@font-face {
  font-family: UOLText;
  src: url(/Assets/Fonts/UolText/uol-text-regular.woff);
  font-weight: normal;
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_regular.otf);
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_bold.otf);
  font-weight: bold;
}

@font-face {
  font-family: UOLDisplay;
  src: url(/Assets/Fonts/UolDisplay/UOLdisplay_light.otf);
  font-weight: light;
}

h1,h2,h3,h4,h5 {
  font-weight: normal;
}

a {
  text-decoration: none;
}


* {
  margin: 0;
  padding: 0;
  border: 0;
  box-sizing: border-box;
  font-family: UOLDisplay;

  &::-webkit-scrollbar {
    width: 13px;
    z-index: 2;
  }
  
  &::-webkit-scrollbar-track {
    background: #ffffff;
    z-index: 2;
  }
  
  &::-webkit-scrollbar-thumb {
    background-color: $light-grey9;
    border-radius: 10px;
    border: 3px solid #ffffff;
    z-index: 2;
  }
}

html,
body {
  overflow-x: hidden;
}


